<template>
    <div class="complaint-container">
        <div class="top">
            <div class="order">
                <router-link v-if="item.order_id" :to="{ path: '/vanzari/comanda/' + item.order_id }">
                    <el-button icon="el-icon-link" type="primary" size="mini" plain>
                        {{ $t('comm.complaints.order') }} {{ item.order_id }}
                    </el-button>
                </router-link>
            </div>
            <div>
                <el-button icon="el-icon-check" size="mini" type="success" @click="resolveComplaint" plain>
                    {{ $t('comm.complaints.mark-resolved') }}
                </el-button>
            </div>
        </div>
        <div class="chat-body">
            <div id="chat-messages">
                <Message :item="item" />
                <Message v-for="message in item.answers" :key="message.id" :item="message" />
            </div>
            <div class="answer-add">
                <!-- Big Add Response button shown when editor is hidden -->
                <div v-if="!showEditor" class="add-response-button-container">
                    <el-button type="primary" size="medium" class="add-response-button" @click="toggleEditor">
                        <i class="el-icon-edit"></i>
                        {{ $t('comm.complaints.add-response') }}
                    </el-button>
                </div>
                <!-- Editor and action buttons when activated -->
                <div v-else class="editor-container">
                    <Editor :text.sync="response" :config="editorConfig" />
                    <div class="editor-actions">
                        <el-button type="primary" @click="sendResponse">
                            {{ $t('comm.complaints.send') }}
                        </el-button>
                        <el-button @click="toggleEditor">
                            {{ $t('comm.complaints.cancel') }}
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const Message = () => import('./message');
import { get, call, sync } from 'vuex-pathify';
const Editor = () => import('@/components/Editor');

export default {
    props: ['item'],
    components: {
        Message,
        Editor,
    },
    data() {
        return {
            response: '',
            showEditor: false,
            editorConfig: {
                height: 100,
            },
        };
    },
    computed: {
        hasResponseFromAffiliate() {
            return this.item.answers.some((answer) => !answer.fromClient);
        },
    },
    methods: {
        sendR: call('communication/SendComplaintResponse'),
        resolveC: call('communication/ResolveComplaint'),
        toggleEditor() {
            this.showEditor = !this.showEditor;
            if (!this.showEditor) {
                this.response = '';
            }
        },
        async sendResponse() {
            if (!this.response || this.response.length < 10) {
                this.sbMsg({
                    message: this.$t('comm.complaints.error-response'),
                    type: 'error',
                });
                return false;
            }
            try {
                await this.sendR({
                    id: this.item.id,
                    response: this.response,
                });
                // Clear the editor after successful send
                this.response = '';
                this.showEditor = false;
            } catch (err) {
                console.error(err);
                this.$reportToSentry(err, {
                    extra: {
                        fn: 'sendResponseComplaint',
                    },
                });
            }
        },
        async resolveComplaint() {
            if (!this.hasResponseFromAffiliate) {
                this.sbMsg({
                    message: this.$t('comm.complaints.error-resolve'),
                });
                return false;
            }
            this.$confirm(this.$t('comm.complaints.confirm-resolve'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.cancel'),
                type: 'warning',
            })
                .then(async () => {
                    try {
                        await this.resolveC(this.item.id);
                    } catch (err) {
                        this.$reportToSentry(err, {
                            extra: {
                                fn: 'resolveComplaint',
                            },
                        });
                    }
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.complaint-container {
    // padding: 20px;
    // background: #f9f9f9;
    border-radius: 4px;
    margin-bottom: 20px;
}

.top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;

    .order {
        margin-right: 10px;
    }
}

.chat-body {
    // background: #fff;
    border-radius: 4px;
    // padding: 15px;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    /* 
    If you want the entire chat to be scrollable at a fixed height,
    you can uncomment the next two lines:
    max-height: 400px;
    overflow-y: auto;
  */

    #chat-messages {
        margin-bottom: 20px;
        position: relative;

        /* 
      Clearfix: if your Message component floats chat bubbles, 
      this ensures the parent (#chat-messages) wraps around them.
    */
        &::after {
            content: '';
            display: table;
            clear: both;
        }

        /*
      Alternatively, if your Message components rely on a flex layout
      instead of float, you can use display: flex; flex-direction: column;
      gap: 10px; etc. For example:
      display: flex;
      flex-direction: column;
      gap: 10px;
    */
    }

    .answer-add {
        text-align: center;
        margin-top: 20px;

        .add-response-button-container {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
        }

        .add-response-button {
            padding: 10px 20px;
        }

        .editor-container {
            margin-top: 10px;
        }

        .editor-actions {
            display: flex;
            justify-content: center;
            margin-top: 10px;

            el-button {
                margin: 0 5px;
            }
        }
    }
}

.bold {
    font-weight: 700;
}
</style>
